
export const AIRTABLE_BASE_ID = 'appomtbO6S2GPNkJY'
export const AIRTABLE_PROBATE_IMPORT_TABLE_ID = 'tblswJJFnGYObPQiS'
export const AIRTABLE_PROBATE_SKIP_TABLE_ID = 'tblrJ9aqkD7lP7VOU'
export const AIRTABLE_FORECLOSURE_IMPORT_TABLE_ID = 'tblAD5vX054lRjLXP'
export const AIRTABLE_FORECLOSURE_SKIP_TABLE_ID = 'tbl2MraUOCz8ejJyn'
export const AIRTABLE_API_KEY = 'keyh4r1ccPOWAwri5'
export const AIRTABLE_Token = 'patimvypqHtp62InE.7eadb4f4242c2340997ed6348cc32e611fb5b39ccb05c1e131d3e4cad0f0ac31'
//export const GoogleMapsAPI = 'AIzaSyDFU3pckpXsf248l9_jUHkfxO7IDYhEi5g'
export const GoogleMapsAPI = 'AIzaSyBVijHMoRmU5eRlVEyxLTupfoyw93_Vf-s'
