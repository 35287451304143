import axios from "axios";
const { AIRTABLE_API_KEY, AIRTABLE_BASE_ID, AIRTABLE_PROBATE_SKIP_TABLE_ID, AIRTABLE_FORECLOSURE_SKIP_TABLE_ID, AIRTABLE_FORECLOSURE_IMPORT_TABLE_ID, AIRTABLE_Token, } = require('./config');

export async function getAirtableData(bodyData) {
  const {
    baseId = AIRTABLE_BASE_ID,
    tableId = AIRTABLE_PROBATE_SKIP_TABLE_ID,
  } = bodyData;

  let allRecords = [];
  let offset = '';
  let hasMoreRecords = true;

  while (hasMoreRecords) {
    const url = `https://api.airtable.com/v0/${baseId}/${tableId}?pageSize=100${offset ? `&offset=${offset}` : ''}`;
    const headers = {Authorization: `Bearer ${AIRTABLE_Token}`};

    try {
      const res = await axios.get(url, {headers});

      const {records, offset: newOffset} = res.data;
     // processRecords(records);
     records.map(rec=>{
      allRecords.push({...rec.fields,id:rec.id});
     })
      
      offset = newOffset;

      hasMoreRecords = Boolean(offset);
    } catch (err) {
      console.error(err);
      break;
    }
  }
  console.log(`Total number of records fetched: ${allRecords.length}`);
  return allRecords

}

export  async function removeRecordsinTable(bodyData) {
  const {
    baseId = AIRTABLE_BASE_ID,
    tableId = AIRTABLE_PROBATE_SKIP_TABLE_ID,
  } = bodyData;
 return await axios.get(`https://api.airtable.com/v0/${baseId}/${tableId}`, {
  headers: {
    'Authorization': `Bearer ${AIRTABLE_Token}`,
    'Content-Type': 'application/json'
  }
})
  .then(res => {
    // extract record IDs from response
    const recordIds = res.data.records.map(record => record.id);
    
    // delete each record
    recordIds.forEach(id => {
      axios.delete(`https://api.airtable.com/v0/${baseId}/${tableId}/${id}`, {
        headers: {
          'Authorization': `Bearer ${AIRTABLE_Token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          console.log(`Record deleted: ${id}`)
        })
        .catch(err => {
          console.error(err)
        });
    });
    return true
  })
  .catch(err => {
    console.error(err)
    return false
  });
}

export async function updateBatchRecords (bodyData)  {
  const {
    baseId = AIRTABLE_BASE_ID,
    tableId = AIRTABLE_FORECLOSURE_IMPORT_TABLE_ID,
    fieldName = "SMS status",
    fieldValue = "Sent",
    updateFieldName="SMS status",
    updateFieldValue="Not send"
  } = bodyData;
  const url = `https://api.airtable.com/v0/${baseId}/${tableId}`;
try {
  const records=await getAirtableData(bodyData)
  const filteredRecords = records.filter(
    (record) => record[fieldName] === fieldValue
  );

  // Update filtered records with new value
  const updatePromises = filteredRecords.map((record) =>
    axios.patch(`${url}/${record.id}`, {
      fields: { [updateFieldName]: updateFieldValue },
    },{
      headers: {
        'Authorization': `Bearer ${AIRTABLE_Token}`,
        'Content-Type': 'application/json'
      }})
  );
  await Promise.all(updatePromises);

  console.log('Updated records:');
  return true
} catch (error) {
  console.log(error)
  return false
}

};
