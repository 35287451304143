import React from "react";
import { useParams } from "react-router";
import { GoogleMapsAPI } from "../config";

const Location = () => {
  let { latlong, address } = useParams();
  return (
    <section>
      <div>
        <iframe
          src={`https://maps.google.com/maps?q=${latlong}&zoom=14&output=embed`}
          //   width="800"
          //   height="300"
          //   style="border:0;"
          allowfullscreen=""
          loading="lazy"
          title="map"
          referrerpolicy="no-referrer-when-downgrade"
          className="map"
        ></iframe>
        <div className="container">
          <div className="location_info_container">
            <h1 className="location_box_title">Missing a unit number?</h1>
            <p className="location_name">
              It seems like the address you entered is missing a unit number.
              Please enter it below
            </p>
            <p className="location_name">{address}</p>
            <p className="location_box_title">Unit Number</p>
            <input type="number" placeholder="e.g 124" className="unit_input" />
            <div className="cta_wrapper">
              <button className="no_unit_cta">No unit number</button>
              <button className="continue_cta">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
