//import logo from "./logo.svg";
import { useState, useEffect } from "react";
import { Button, Col, Row, Card, Input, Select, Modal, Space, Table, Popconfirm, Radio, Tabs, Tag, Badge, Dropdown, notification, List, Skeleton } from "antd";
import axios from "axios";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { getAirtableData, updateBatchRecords } from "../airtable";
import { AIRTABLE_BASE_ID, AIRTABLE_FORECLOSURE_IMPORT_TABLE_ID, AIRTABLE_FORECLOSURE_SKIP_TABLE_ID, AIRTABLE_PROBATE_IMPORT_TABLE_ID, AIRTABLE_PROBATE_SKIP_TABLE_ID } from "../config";
import { DownOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { Dragger } = Upload;
//const URL = "http://localhost:4155";
const URL = "https://nyapi.offerbeyond.com";

const probateImportColumns = [

  {
    title: 'Decedent Name',
    dataIndex: 'Decedent Full Name',
    key: 'decedentName',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Deceased Date',
    dataIndex: 'Deceased Date',
    key: 'date',
  },
  {
    title: 'Decedent Street',
    dataIndex: 'Decedent Street',
    key: 'mailStreet',
  },
  {
    title: 'Petitioner',
    dataIndex: 'Petitioner',
    key: 'petitioner',
  },
  {
    title: 'Petitioner Address',
    dataIndex: 'Petitioner Street',
    key: 'mailAddress',
  },
  {
    title: 'Petitioner Number',
    dataIndex: 'Petitioner Phone Number',
    key: 'phoneNumber',
  },
  {
    title: 'Property Address',
    dataIndex: 'Decedent Address',
    key: 'propertyAddress',
  },
  {
    title: 'Attachment',
    dataIndex: 'Attachments',
    key: 'Attachments',
    render: (text) => <a href={text} target="_blank" rel="noreferrer">{text ? text.slice(text.length - 17) : ''}</a>,
  },

];

const forclosureSkiptracingcolumns = [
  {
    title: 'Petitioner Information',
    children: [
      {
        title: 'First Name',
        dataIndex: 'first name',
        key: 'firstName',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: 'Last Name',
        dataIndex: 'last name',
        key: 'lastName',
      },
      {
        title: 'Street',
        dataIndex: 'petitioner street',
        key: 'petitionerStreet',
      },
      {
        title: 'City',
        dataIndex: 'petitioner city',
        key: 'petitionerCity',
      },
      {
        title: 'County',
        dataIndex: 'petitioner county',
        key: 'petitionerCounty',
      },
      {
        title: 'Zip',
        dataIndex: 'petitioner zip',
        key: 'petitionerZip',
      },
    ],
  },
  {
    title: 'Phone Number',
    children: [
      // {
      //   title: 'Carrier',
      //   dataIndex: 'phoneNumber1Carrier',
      //   key: 'phoneNumber1Carrier',
      // },
      {
        title: 'Type',
        dataIndex: 'phoneNumber1 type',
        key: 'phoneNumber1Type',
      },
      {
        title: 'Number',
        dataIndex: 'phoneNumber1 number',
        key: 'phoneNumber1Number',
      },
      {
        title: 'SMS status',
        dataIndex: 'SMS status',
        key: 'SMSstatus',
        render: (text) => (
          <Tag color={text === 'Sent' ? "blue" : "yellow"} key={text}>
            {text}
          </Tag>
        ),
      },
    ],

  },
  {
    title: 'Lead Status',
    dataIndex: 'Lead Status',
    key: 'Lead Status',
    render: (text) => <Badge status={text === 'Interested' ? "success" : "warn"} text={text} />
  },
];
const foreclosureImportColumns = [
  {
    title: 'Borrower',
    dataIndex: 'Borrower',
    key: 'Borrower',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Address',
    dataIndex: 'Address',
    key: 'Address',
  },
  {
    title: 'City',
    dataIndex: 'City',
    key: 'City',
  },
  {
    title: 'State',
    dataIndex: 'State',
    key: 'State',
  },
  {
    title: 'Zip',
    dataIndex: 'Zip',
    key: 'Zip',
  },
  {
    title: 'County',
    dataIndex: 'County',
    key: 'County',
  },
  {
    title: 'Original Trustee',
    dataIndex: 'originalTrustee',
    key: 'originalTrustee',
  },
  {
    title: 'Attachment',
    dataIndex: 'Attachments',
    key: 'Attachments',
    render: (text) => <a href={text} target="_blank" rel="noreferrer">{text ? text.slice(text.length - 17) : text}</a>,

  },
];
async function sendSMSTable({ tableId, fileName, interval, followUpInterval, templates }) {
  await axios
    .post(URL + "/api/sendsms", { tableId, fileName, interval, followUpInterval, templates })
    .then((res) => {

      message.success(`Message sent`);
    })
    .catch((err) => { message.error(`Error! Failed sending message`); });
}

async function addTemplateMsgToServer({ title, content, tags }) {
  return await axios
    .post(URL + "/api/templatemsg", { title, content, tags })
    .then((res) => {
      if (res.data.status) {
        message.success(res.data.comment)
        return true
      }
      else {
        message.warning(res.data.comment)
        return false
      }
    })
    .catch((err) => {
      message.error(`Error! Network Error`);
      return false
    });
}

async function removeChat({ number }) {
  await axios
    .post(URL + "/api/removechat", { number: "1" + number })
    .then((res) => {
      //message.success(`Message sent to +1${phoneNumber}`);
      return true
    })
    .catch((err) => {
      return false
      //message.error(`Error! Failed sending message`);
    });
}
async function removeRecords({ payloadArray, data }) {
  return await axios
    .post(URL + "/api/removerecords", { payloadArray, data })
    .then((res) => {
      return true
    })
    .catch((err) => {
      return false
    });
}

function Main() {
  const [inputText, setInputText] = useState("");
  const [openaiKey, setOpenaiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [skiploading, setSkipLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTemplateMSGModalOpen, setIsTemplateMSGModalOpen] = useState(false);
  const [isEditTemplateMSGModalOpen, setIsEditTemplateMSGModalOpen] = useState(false);
  const [intervalSelectState, setIntervalSelect] = useState(Array(200).fill('30000'));
  const [followUpIntervalSelectState, setFollowUpIntervalSelect] = useState(Array(200).fill('1'));
  const [templateMsgTableSelectState, setTemplateMsgTableSelect] = useState(Array(200).fill([]));

  const [resetSMSForeclosureState, setResetSMSForeclosure] = useState(Array(1000).fill(false));
  const [resetSMSprobateState, setResetSMSProbate] = useState(Array(1000).fill(false));

  const [probateForeclosureImportDataState, setProbateForeclosureImportData] = useState(false);
  const [ForeclosureImportDataState, setForeclosureImportTableData] = useState([]);

  const [templateMsgTextAreaState, setTemplateMsgTextArea] = useState('');
  const [templateMsgTitleState, setTemplateMsgTitle] = useState('');

  const [probateForeclosureSkipTracingDataState, setProbateForeclosureSkipTracingData] = useState(false);
  const [probateSkipTracingTableState, setProbateSkipTracingTableData] = useState([]);
  const [probateImportTableState, setProbateImportTableData] = useState([]);

  const [ForeclosureSkipTracingTableState, setForeclosureSkipTracingTableData] = useState([]);
  const [expandedRowsChangeState, setExpandedRowsChange] = useState([]);
  const [parentImportTableDataState, setParentImportTableData] = useState([]);
  const [parentSkipTraceTableDataState, setparentSkipTraceTableData] = useState([]);
  //////////////////////////////
  const [expandedProbateRowsChangeState, setExpandedProbateRowsChange] = useState([]);
  const [parentProbateImportTableDataState, setParentProbateImportTableData] = useState([]);
  const [parentProbateSkipTraceTableDataState, setParentProbateSkipTraceTableData] = useState([]);
  const [templateMsgList, setTemplateMsgList] = useState([]);
  const [templateMsgLoading, setTemplateMsgLoading] = useState(false);
  const [templateMsgTitleStore, setTemplateMsgTitleStore] = useState('');
  const [templateMsgTableOptions, setTemplateMsgTableOptions] = useState([]);

  const [probateImportSelectedRowsState, setProbateImportSelectedRows] = useState([]);
  const [probateSkipTraceSelectedRowsState, setProbateSkipTraceSelectedRows] = useState([]);

  const [foreclosureImportSelectedRowsState, setForeclosureImportSelectedRows] = useState([]);
  const [foreclosureSkipTraceSelectedRowsState, setForeclosureSkipTraceSelectedRows] = useState([]);

  const [api, contextHolder] = notification.useNotification();
  const tagOptions = [{ label: "First Name", key: "firstName" }, { label: "Last Name", key: "lastName" }, { label: "Company Name", key: "companyName" }, { label: "Phone", key: "phone" }, { label: "Email", key: "email" }, { label: "City", key: "city" }, { label: "Street", key: "street" }];


  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    });
  };
  const smsSelectItems = [
    {
      key: '1',
      label: 'Send',
    },
    {
      key: '2',
      danger: true,
      label: 'Reset status',
    },
  ];
  const extractedProbateImportParentTableColumns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: (a, b) => a.fileName.localeCompare(b.fileName)
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',

      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Delete the record?"
            description="Are you sure to delete this record?"
            onConfirm={() => deleteRecordParentConfirm(record, 'probate-import')}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>

        </Space>
      )
    },
  ]
  const extractedForeclosureImportParentTableColumns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: (a, b) => a.fileName.localeCompare(b.fileName)
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',

      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Delete the record?"
            description="Are you sure to delete this record?"
            onConfirm={() => deleteRecordParentConfirm(record, 'foreclosure-import')}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>

        </Space>
      )
    },
  ]

  const intervalMenuColumns = [
    {
      value: '30000',
      label: '30 sec',
    },
    {
      value: '60000',
      label: '1 min',
    },
    {
      value: '90000',
      label: '1 min 30 sec',
    },
    {
      value: '120000',
      label: '2 min',
    },
    {
      value: '150000',
      label: '2 min 30 sec',
    },
    {
      value: '180000',
      label: '3 min',
    },
    {
      value: '210000',
      label: '3 min 30 sec',
    },
    {
      value: '240000',
      label: '4 min',
    },
    {
      value: '270000',
      label: '4 min 30 sec',
    },
    {
      value: '300000',
      label: '5 min',
    },]
  const followUpIntervalMenuColumns = [
    {
      value: '1',
      label: '1 Day',
    },
    {
      value: '2',
      label: '2 Day',
    },
    {
      value: '3',
      label: '3 Day',
    },
    {
      value: '4',
      label: '4 Day',
    },
    {
      value: '5',
      label: '5 Day',
    },
    {
      value: '7',
      label: '1 Week',
    },
    {
      value: '14',
      label: '2 Weeks',
    },
    {
      value: '21',
      label: '3 Weeks',
    },
    {
      value: '30',
      label: '1 Month',
    },
    {
      value: '60',
      label: '2 Months',
    },
    {
      value: '90',
      label: '3 Months',
    },
  ]
  const extractedForeclosureSkipTracingParentTableColumns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: (a, b) => a.fileName.localeCompare(b.fileName)
    },
    {
      title: 'SMS',
      key: 'sms',
      align: 'center',
      render: (text, record) => (
        <Dropdown
          menu={{
            items: smsSelectItems,
            onClick: ({ key }) => onClickSMSMore(record, key, 'foreclosure')
          }}
          disabled={resetSMSForeclosureState[record.key]}
        >
          <a>
            <Badge status={!resetSMSForeclosureState[record.key] ? "success" : "warning"} text={"   "} />
            Action <DownOutlined />
          </a>

        </Dropdown>
      )
    },
    {
      title: 'Follow-Up',
      dataIndex: 'followUpCount',
      key: 'followUpCount',
      width: 100,
      align: 'center',

      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Timer',
      children: [
        {
          title: 'Interval',
          key: 'interval',
          align: 'center',
          render: (text, record) => (<Select
            labelInValue
            defaultValue={{
              value: '30000',
              label: '30 sec',
            }}
            style={{
              width: 120,
            }}
            onChange={(value) => handleSelectInterval(record, value)}
            options={intervalMenuColumns}
          />)
        },
        {
          title: 'Follow Up Interval',
          key: 'fuinterval',
          align: 'center',
          render: (text, record) => (<Select
            labelInValue
            defaultValue={{
              value: '1',
              label: '1 Day',
            }}
            style={{
              width: 120,
            }}
            onChange={(value) => handleSelectFollowUpInterval(record, value)}
            options={followUpIntervalMenuColumns}
          />)
        },
      ],
    },
    {
      title: 'Select Template messages',
      key: 'selectTemplatemsg',
      align: 'center',
      render: (text, record) => (<Select
        mode="multiple"
        style={{
          width: '100%',
        }}
        placeholder="Please select templates"
        defaultValue={templateMsgTableOptions.map(val => val.value)}
        onChange={(value) => handleSelectTemplateMsgChangeTable(record, value)}
        options={templateMsgTableOptions}
      />)
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',

      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Delete the record?"
            description="Are you sure to delete this task?"
            onConfirm={() => deleteRecordParentConfirm(record, 'foreclosure-skiptrace')}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>

        </Space>
      )
    },
  ]
  const extractedProbateSkipTracingParentTableColumns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'SMS',
      key: 'sms',
      align: 'center',
      render: (text, record) => (
        <Dropdown
          menu={{
            items: smsSelectItems,
            onClick: ({ key }) => onClickSMSMore(record, key, 'probate')
          }}
          disabled={resetSMSprobateState[record.key]}
        >
          <a>
            <Badge status={!resetSMSprobateState[record.key] ? "success" : "warning"} text={"   "} />
            Action <DownOutlined />
          </a>

        </Dropdown>
      )
    },
    {
      title: 'Follow-Up',
      dataIndex: 'followUpCount',
      key: 'followUpCount',
      width: 100,
      align: 'center',
    },
    {
      title: 'Timer',
      children: [
        {
          title: 'Interval',
          key: 'interval',
          align: 'center',
          render: (text, record) => (<Select
            labelInValue
            defaultValue={{
              value: '30000',
              label: '30 sec',
            }}
            style={{
              width: 120,
            }}
            onChange={(value) => handleSelectInterval(record, value)}
            options={intervalMenuColumns}
          />)
        },
        {
          title: 'Follow Up Interval',
          key: 'fuinterval',
          align: 'center',
          render: (text, record) => (<Select
            labelInValue
            defaultValue={{
              value: '1',
              label: '1 Day',
            }}
            style={{
              width: 120,
            }}
            onChange={(value) => handleSelectFollowUpInterval(record, value)}
            options={followUpIntervalMenuColumns}
          />)
        },
      ],
    },
    {
      title: 'Select Template messages',
      key: 'selectTemplatemsg',
      align: 'center',
      render: (text, record) => (<Select
        mode="multiple"
        style={{
          width: '100%',
        }}
        placeholder="Please select templates"
        defaultValue={templateMsgTableOptions.map(val => val.value)}
        onChange={(value) => handleSelectTemplateMsgChangeTable(record, value)}
        options={templateMsgTableOptions}
      />)
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',

      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Delete the record?"
            description="Are you sure to delete this record?"
            onConfirm={() => deleteRecordParentConfirm(record, 'probate-skiptrace')}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>

        </Space>
      )
    },
  ]

  const onClickSMSMore = (record, key, docType) => {
    console.log({ docType })
    if (key === '1') {
      openNotificationWithIcon('info', 'SMS Action Info', `Start to send the SMS for ${record.fileName}`)
      onSendSMS(docType, record)
    }
    else if (key === '2') {

      openNotificationWithIcon('info', 'SMS Action Info', `Remove SMS status for ${record.fileName}`)
      onResetSMS(docType, record)
    }
  };
  const deleteRecordParentConfirm = async (e, data) => {
    let payloadArray = []
    if (data === 'probate-import') {
      payloadArray = [...payloadArray, ...probateImportSelectedRowsState]
      if (!probateImportSelectedRowsState.length)
        payloadArray.push(e)

    }
    if (data === 'probate-skiptrace') {
      payloadArray = [...payloadArray, ...probateSkipTraceSelectedRowsState]
      if (!probateSkipTraceSelectedRowsState.length)
        payloadArray.push(e)
    }
    if (data === 'foreclosure-import') {
      payloadArray = [...payloadArray, ...foreclosureImportSelectedRowsState]
      if (!foreclosureImportSelectedRowsState.length)
        payloadArray.push(e)
    }
    if (data === 'foreclosure-skiptrace') {
      payloadArray = [...payloadArray, ...foreclosureSkipTraceSelectedRowsState]
      if (!foreclosureSkipTraceSelectedRowsState.length)
        payloadArray.push(e)
    }

    const result = await removeRecords({ payloadArray, data })
    if (result)
      openNotificationWithIcon('success', 'Airtable Action Info', `The Data will be updated in one minute.`)
  };
  const handleSelectFollowUpInterval = (record, { value }) => {
    setFollowUpIntervalSelect(prevState => {
      const newState = [...prevState];
      newState[record.key] = value;
      return newState;
    });
  };
  const handleSelectTemplateMsgChangeTable = (record, value) => {
    setTemplateMsgTableSelect(prevState => {
      const newState = [...prevState];
      newState[record.key] = value;
      return newState;
    });
  };
  const handleSelectInterval = (record, { value }) => {
    setIntervalSelect(prevState => {
      const newState = [...prevState];
      newState[record.key] = value;
      return newState;
    });
  };
  const onChangeTemplateMsgTextArea = (e) => {
    setTemplateMsgTextArea(e.target.value)
  }
  const expandedForclosureImportRowRender = (index) => {
    const childTableData = ForeclosureImportDataState.filter(data => index.fileName === data.fileName)
    return <Table columns={foreclosureImportColumns} dataSource={childTableData} pagination={true} />;
  };
  const expandedForeclosureSkipTraceRowRender = (index) => {
    const childTableData = ForeclosureSkipTracingTableState.filter(data => index.fileName === data.fileName)
    return <Table columns={forclosureSkiptracingcolumns} dataSource={childTableData} pagination={true} />;
  };
  ////////////////////////
  const expandedProbateImportRowRender = (index) => {
    const childTableData = probateImportTableState.filter(data => index.fileName === data.fileName)
    return <Table columns={probateImportColumns} dataSource={childTableData} pagination={true} />;
  };
  const expandedProbateSkipTraceRowRender = (index) => {
    const childTableData = probateSkipTracingTableState.filter(data => index.fileName === data.fileName)
    return <Table columns={forclosureSkiptracingcolumns} dataSource={childTableData} pagination={true} />;
  };
  useEffect(() => {
    let parentImportTableData = [];
    let tempParentTableData = [];
    ForeclosureImportDataState.map((arr) => {
      if (arr.fileName)
        tempParentTableData.push(arr.fileName)
    })
    tempParentTableData = [...new Set(tempParentTableData)]
    tempParentTableData.map((fileName, key) => {
      const tempFilter = ForeclosureImportDataState.filter(data => data.fileName === fileName)
      parentImportTableData.push({ key, fileName })
    })
    let parentSkipTraceTableData = [];
    tempParentTableData = [];
    ForeclosureSkipTracingTableState.map((arr) => {
      if (arr.fileName)
        tempParentTableData.push(arr.fileName)
    })
    tempParentTableData = [...new Set(tempParentTableData)]

    tempParentTableData.map((fileName, key) => {
      const tempFilter = ForeclosureSkipTracingTableState.filter(data => data.fileName === fileName)
      parentSkipTraceTableData.push({ key, fileName, followUpCount: tempFilter[0]?.followUpCount })
    })
    setParentImportTableData(parentImportTableData)
    setparentSkipTraceTableData(parentSkipTraceTableData)
  }, [ForeclosureImportDataState, ForeclosureSkipTracingTableState]);

  useEffect(() => {
    let parentImportTableData = [];
    let tempParentTableData = [];
    probateImportTableState.map((arr) => {
      if (arr.fileName)
        tempParentTableData.push(arr.fileName)
    })
    tempParentTableData = [...new Set(tempParentTableData)]
    tempParentTableData.map((fileName, key) => {
      const tempFilter = probateImportTableState.filter(data => data.fileName === fileName)
      parentImportTableData.push({ key, fileName })
    })
    let parentSkipTraceTableData = [];
    tempParentTableData = [];
    probateSkipTracingTableState.map((arr) => {
      if (arr.fileName)
        tempParentTableData.push(arr.fileName)
    })
    tempParentTableData = [...new Set(tempParentTableData)]

    tempParentTableData.map((fileName, key) => {
      const tempFilter = probateSkipTracingTableState.filter(data => data.fileName === fileName)
      parentSkipTraceTableData.push({ key, fileName, followUpCount: tempFilter[0]?.followUpCount })
    })
    setParentProbateImportTableData(parentImportTableData)
    setParentProbateSkipTraceTableData(parentSkipTraceTableData)
  }, [probateImportTableState, probateSkipTracingTableState]);
  useEffect(() => {
    const fetchData = async () => {
      await axios.get(URL + "/api/templatemsgs")
        .then((res) => {
          setTemplateMsgList(res.data.result);
          const data = res.data.result.map(val => {
            return {
              value: val.title,
              label: val.title,
            }
          })
          setTemplateMsgTableOptions(data)
        });
    }
    fetchData()

  }, [templateMsgLoading]);
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Update skiptable data');
      setProbateForeclosureSkipTracingData((preValue) => {
        return !preValue
      })
      setProbateForeclosureImportData((preValue) => {
        return !preValue
      })
    }, 60000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const probateData = await getAirtableData({ baseId: AIRTABLE_BASE_ID, tableId: AIRTABLE_PROBATE_IMPORT_TABLE_ID })
      const forclosureData = await getAirtableData({ baseId: AIRTABLE_BASE_ID, tableId: AIRTABLE_FORECLOSURE_IMPORT_TABLE_ID })
      // console.log(probateData, "probateData")
      setProbateImportTableData(probateData)
      setForeclosureImportTableData(forclosureData)
    }

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [probateForeclosureImportDataState])
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const probateData = await getAirtableData({ baseId: AIRTABLE_BASE_ID, tableId: AIRTABLE_PROBATE_SKIP_TABLE_ID })
      const forclosureData = await getAirtableData({ baseId: AIRTABLE_BASE_ID, tableId: AIRTABLE_FORECLOSURE_SKIP_TABLE_ID })
      // console.log(probateData, "probateData")
      //console.log(forclosureData, "forclosureData")
      setProbateSkipTracingTableData(probateData)
      setForeclosureSkipTracingTableData(forclosureData)
    }

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [probateForeclosureSkipTracingDataState])
  const onClickTemplateMsgListEdit = ({ item }) => {
    setTemplateMsgTitle(item.title)
    setTemplateMsgTextArea(item.content)
    setTemplateMsgTitleStore(item.title)
    setIsEditTemplateMSGModalOpen(true)
  };
  const onClickTemplateMsgListDelete = async ({ item }) => {
    await axios.post(URL + "/api/removetemplatemsg", { title: item.title })
      .then((res) => {
        console.log(res.data)
        if (res.data.status === 200)
          message.success('Deleted Template message!')
        else
          if (res.data.status === 405)
            message.error('Can not delete Template message!')
        setTemplateMsgLoading(!templateMsgLoading)
      }).catch(err => { message.error('Network ERR!') });
  };

  const handleOk = async () => {
    const response = await axios.post(URL + '/api/key', { key: openaiKey });
    if (response.data?.data === 'success')
      message.success(`The key is changed.`);
    else message.error(`Failed!`);
    setIsModalOpen(false);
  };
  const runGPT = async () => {
    setLoading(true);
    await axios
      .post(URL + "/api/result")
      .then((res) => {
        if (res.data.status === 200) {
          openNotificationWithIcon('success', 'Airtable Action Info', `The data is loaded`)
          setProbateForeclosureImportData((preValue) => {
            return !preValue
          })

        }
        else if (res.data.status === 500)
          openNotificationWithIcon('error', 'Airtable Action Info', `The data is failed`)
        else if (res.data.status === 403)
          message.error(`No data loaded. Please upload again.`);
      })
      .catch((err) => { });
    setLoading(false);
  };
  const skipTracing = async () => {
    setSkipLoading(true);
    await axios
      .post(URL + "/api/skiptrace", { probates: probateImportSelectedRowsState, foreclosures: foreclosureImportSelectedRowsState })
      .then((res) => {
        setProbateForeclosureSkipTracingData((preValue) => {
          return !preValue
        })
        openNotificationWithIcon('success', 'Airtable Action Info', `Skip tracing successfully!`)
      }).catch((err) => {
        openNotificationWithIcon('error', 'Airtable Action Info', `Skip tracing failed!`)
      });
    setSkipLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleEditTemplateMSGCancel = () => {
    setTemplateMsgTextArea('')
    setTemplateMsgTitle('')
    setTemplateMsgTitleStore('')
    setIsEditTemplateMSGModalOpen(false);
  };
  const handleEditTemplateMSGOk = async () => {
    await axios
      .post(URL + "/api/edittemplatemsg", { oldTitle: templateMsgTitleStore, title: templateMsgTitleState, content: templateMsgTextAreaState })
      .then((res) => {
        if (res.data.result.status) {
          message.success(res.data.result.comment)
          return true
        }
        else {
          message.warning(res.data.result.comment)
          return false
        }
      })
      .catch((err) => {
        message.error(`Error! Network Error`);
        return false
      });
    setTemplateMsgTitleStore('')
    setTemplateMsgLoading(!templateMsgLoading)
  }
  const handleTemplateMSGCancel = () => {
    setIsTemplateMSGModalOpen(false);
  };
  const handleTemplateMSGOk = async () => {

    const payload = { title: templateMsgTitleState, content: templateMsgTextAreaState, tags: [] }
    const result = await addTemplateMsgToServer(payload)
    if (result) {
      setTemplateMsgTextArea('')
      setTemplateMsgTitle('')
      setTemplateMsgLoading(!templateMsgLoading)
    }


  };
  const handleTemplateMsgTagMenuClick = (e) => {
    setTemplateMsgTextArea(templateMsgTextAreaState + "{" + e.key + "}")
  };
  const onResetSMS = async (data, record) => {
    if (data === 'probate') {
      setResetSMSProbate(prevState => {
        const newState = [...prevState];
        newState[record.key] = true;
        return newState;
      });
      const resultResetSMS = await updateBatchRecords({
        tableId: AIRTABLE_PROBATE_SKIP_TABLE_ID,
        fieldName: "fileName",
        fieldValue: record.fileName,
        updateFieldName: "SMS status",
        updateFieldValue: "Not send"
      })
      message.success(`SMS status is reset.`);
      const resultResetLead = await updateBatchRecords({
        tableId: AIRTABLE_PROBATE_SKIP_TABLE_ID,
        fieldName: "fileName",
        fieldValue: record.fileName,
        updateFieldName: "Lead Status",
        updateFieldValue: null
      })
      message.success(`Lead Status is reset.`);
      const filteredTableData = probateSkipTracingTableState.filter(data => data.fileName === record.fileName)
      for (let i = 0; i < filteredTableData.length; i++) {
        await removeChat({ number: filteredTableData[i]['phoneNumber1 number'] })
      }
      setResetSMSProbate(prevState => {
        const newState = [...prevState];
        newState[record.key] = false;
        return newState;
      });

      if (resultResetSMS && resultResetLead)
        message.success(`${record.fileName} is reset.`);
      else message.error(`Reset failed for ${record.fileName} !`);
    } else {
      setResetSMSForeclosure(prevState => {
        const newState = [...prevState];
        newState[record.key] = true;
        return newState;
      });
      const resultResetSMS = await updateBatchRecords({
        tableId: AIRTABLE_FORECLOSURE_SKIP_TABLE_ID,
        fieldName: "fileName",
        fieldValue: record.fileName,
        updateFieldName: "SMS status",
        updateFieldValue: "Not send"
      })
      message.success(`SMS status is reset.`);
      const resultResetLead = await updateBatchRecords({
        tableId: AIRTABLE_FORECLOSURE_SKIP_TABLE_ID,
        fieldName: "fileName",
        fieldValue: record.fileName,
        updateFieldName: "Lead Status",
        updateFieldValue: null
      })
      message.success(`Lead Status is reset.`);
      const filteredTableData = ForeclosureSkipTracingTableState.filter(data => data.fileName === record.fileName)
      for (let i = 0; i < filteredTableData.length; i++) {
        await removeChat({ number: filteredTableData[i]['phoneNumber1 number'] })
      }
      setResetSMSForeclosure(prevState => {
        const newState = [...prevState];
        newState[record.key] = false;
        return newState;
      });

      if (resultResetSMS && resultResetLead)
        openNotificationWithIcon('success', 'Airtable Action Info', `${record.fileName} is reset.`)
      else openNotificationWithIcon('error', 'Airtable Action Info', `${record.fileName} is failed.`);
    }
    /// Reload skip tracing data
    setProbateForeclosureSkipTracingData((preValue) => {
      return !preValue
    })
  }
  const onSendSMS = async (data, record) => {
    if (data === 'probate') {
      setResetSMSProbate(prevState => {
        const newState = [...prevState];
        newState[record.key] = true;
        return newState;
      });
      //const filteredTableData = probateSkipTracingTableState.filter(data => data.fileName === record.fileName).filter(data => data['SMS status'] !== 'Sent')
      await sendSMSTable({ tableId: AIRTABLE_PROBATE_SKIP_TABLE_ID, fileName: record.fileName, interval: intervalSelectState[record.key], followUpInterval: followUpIntervalSelectState[record.key], templates: templateMsgTableSelectState[record.key] })

      setResetSMSProbate(prevState => {
        const newState = [...prevState];
        newState[record.key] = false;
        return newState;
      });
    } else {
      setResetSMSForeclosure(prevState => {
        const newState = [...prevState];
        newState[record.key] = true;
        return newState;
      });
      // const filteredTableData = ForeclosureSkipTracingTableState.filter(data => data.fileName === record.fileName).filter(data => data['SMS status'] !== 'Sent')
      await sendSMSTable({ tableId: AIRTABLE_FORECLOSURE_SKIP_TABLE_ID, fileName: record.fileName, interval: intervalSelectState[record.key], followUpInterval: followUpIntervalSelectState[record.key], templates: templateMsgTableSelectState[record.key] })

      setResetSMSForeclosure(prevState => {
        const newState = [...prevState];
        newState[record.key] = false;
        return newState;
      });
    }
    message.success(`All Messages sent`);
  };

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf" || file.type === "application/vnd.ms-excel";
      if (!isPDF) {
        message.error(`${file.name} is not a pdf or excel file`);
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    customRequest: async ({ file, onSuccess, onError, onProgress }) => {
      let formData = new FormData();
      // console.log(file, "@@");
      formData.append("pdfs", file);
      axios
        .post(URL +"/api/upload", formData)
        .then((res) => {
          // setStoreText(res.data.data);
          onSuccess(null, "image");
        })
        .catch((err) => {
          onError(null, "image");
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Col>
      {contextHolder}
      <Row style={{ padding: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Col >
          <Row >
            <Card bordered={false}>
              <Dragger {...props}  >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint" style={{ height: 10 }}>
                  Support for a single or bulk upload. Strictly prohibit from
                  uploading company data or other band files
                </p>
              </Dragger>
            </Card>
          </Row>
        </Col>
      </Row>
      <Row style={{ padding: 10 }}>
        <Col span={24}>
          <Space wrap>
            <Button
              onClick={async () => {
                await axios
                  .post(URL + "/api/reset")
                  .then(() => {
                    message.success("Document is reset");
                  })
                  .catch(() => {
                    message.success("Server error");
                  });
              }}
            >
              Reset
            </Button>
            <Button onClick={() => setIsModalOpen(true)}>Set OpenAI APIKEY</Button>
            <Button onClick={() => runGPT()} loading={loading} >Extract the info</Button>
           {/*  <Button onClick={() => skipTracing()} loading={skiploading} >Skip Tracing</Button>
            <Button onClick={() => setIsTemplateMSGModalOpen(true)} >Edit Template message</Button> */}
          </Space>
        </Col>
      </Row>
      <Row style={{ padding: 10 }}>

        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"middle"}
          items={new Array(2).fill(null).map((_, i) => {
            const id = String(i + 1);
            return {
              label: Number(id) === 1 ? 'Probate' : 'Foreclosure',
              key: id,
              children: Number(id) === 1 ? (<Row style={{ padding: 10 }}>
                <Col span={24}>
                  <Row span={24} style={{ padding: 5 }}>
                    Extracted PDF Data
                    <Table scroll={{ x: 'max-content' }} bordered style={{ width: "100%" }}
                      rowSelection={{
                        type: 'checkbox',
                        onChange: (_, selectedRows) => {
                          console.log('selectedRows: ', selectedRows);
                          setProbateImportSelectedRows(selectedRows)
                        },
                      }}
                      expandable={{
                        expandedRowRender: expandedProbateImportRowRender,
                        defaultExpandedRowKeys: ['0'],
                        expandedRowKeys: expandedProbateRowsChangeState,
                        onExpandedRowsChange: (keys) => { setExpandedProbateRowsChange(keys) }
                      }}

                      size="middle" columns={extractedProbateImportParentTableColumns} dataSource={parentProbateImportTableDataState} />
                  </Row>
                </Col>
             {/*    <Col>
                  <Row style={{ padding: 5 }}>
                  </Row>
                </Col>

                <Col span={24}>
                  PDF Skip Traced Data
                  <Table scroll={{ x: 'max-content' }} bordered style={{ width: "100%" }}
                    size="middle"
                    rowSelection={{
                      type: 'checkbox',
                      onChange: (_, selectedRows) => {
                        console.log('selectedRows: ', selectedRows);
                        setProbateSkipTraceSelectedRows(selectedRows)
                      },
                    }}
                    expandable={{
                      expandedRowRender: expandedProbateSkipTraceRowRender,
                      defaultExpandedRowKeys: ['0'],
                      expandedRowKeys: expandedProbateRowsChangeState,
                      onExpandedRowsChange: (keys) => { setExpandedProbateRowsChange(keys) }
                    }}
                    columns={extractedProbateSkipTracingParentTableColumns} dataSource={parentProbateSkipTraceTableDataState} />
                </Col> */}
                <Modal title="Set your OpenAI api key." open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                  <Input value={openaiKey} onChange={(e) => setOpenaiKey(e.target.value)}></Input>
                </Modal>
              </Row>) : (<Row style={{ padding: 10 }}>
                <Col span={24}>
                  <Row span={24} style={{ padding: 5 }}>
                    Extracted Xlsx Data
                    <Table scroll={{ x: 'max-content' }} bordered style={{ width: "100%" }}
                      rowSelection={{
                        type: 'checkbox',
                        onChange: (_, selectedRows) => {
                          console.log('selectedRows: ', selectedRows);
                          setForeclosureImportSelectedRows(selectedRows)
                        },
                      }}
                      expandable={{
                        expandedRowRender: expandedForclosureImportRowRender,
                        defaultExpandedRowKeys: ['0'],
                        expandedRowKeys: expandedRowsChangeState,
                        onExpandedRowsChange: (keys) => { setExpandedRowsChange(keys) }
                      }}

                      size="middle" columns={extractedForeclosureImportParentTableColumns} dataSource={parentImportTableDataState} />
                  </Row>
                </Col>
               {/*  <Col span={24}>
                  Skip Traced Data
                  <Table scroll={{ x: 'max-content' }} bordered style={{ width: "100%" }}
                    size="middle"
                    rowSelection={{
                      type: 'checkbox',
                      onChange: (_, selectedRows) => {
                        console.log('selectedRows: ', selectedRows);
                        setForeclosureSkipTraceSelectedRows(selectedRows)
                      },
                    }}
                    expandable={{
                      expandedRowRender: expandedForeclosureSkipTraceRowRender,
                      defaultExpandedRowKeys: ['0'],
                      expandedRowKeys: expandedRowsChangeState,
                      onExpandedRowsChange: (keys) => { setExpandedRowsChange(keys) }
                    }}
                    columns={extractedForeclosureSkipTracingParentTableColumns} dataSource={parentSkipTraceTableDataState} />
                </Col> */}
                <Modal title="Set your OpenAI api key." open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                  <Input value={openaiKey} onChange={(e) => setOpenaiKey(e.target.value)}></Input>
                </Modal>
              </Row>),
            };
          })}
        />
      </Row>
      <Modal title="Template Message" width={"60%"} open={isTemplateMSGModalOpen} okText={"Save"} onOk={handleTemplateMSGOk} onCancel={handleTemplateMSGCancel}>
        <Row>
          <Col span={14}  >
            <Col span={22}>
              Template Name
              <Input showCount maxLength={50} value={templateMsgTitleState} onChange={(e) => setTemplateMsgTitle(e.target.value)} placeholder="Template message title" />
              <br />
              <br />
              Template message
              <TextArea value={templateMsgTextAreaState} placeholder="Template message content" maxLength={1000}
                autoSize={{
                  minRows: 3,
                }}
                onChange={(e) => onChangeTemplateMsgTextArea(e)}
              />
            </Col>
          </Col>
          <Col span={10} >
            <Row style={{ padding: 5 }}>
              Insert Tags
            </Row>
            <Row style={{ padding: 5 }}>
              <Dropdown menu={{ items: tagOptions, onClick: handleTemplateMsgTagMenuClick }}>
                <Button>
                  <Space>
                    Select Tag
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Row>
          </Col>
          <Col span={24}>
            <Row style={{ padding: 5 }}>Registered Templates</Row>
            <List
              pagination={{
                position: "bottom",
                align: "end",
              }}
              bordered
              size="small"
              itemLayout="horizontal"
              dataSource={templateMsgList}
              renderItem={(item, index) => (
                <List.Item
                  actions={[<a key={index} onClick={() => onClickTemplateMsgListEdit({ item })} >edit</a>, <a key={index} onClick={() => onClickTemplateMsgListDelete({ item })}>delete</a>]}
                >
                  <List.Item.Meta
                    title={<p ><bold>{item.title}</bold></p>}
                    description={item.content}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Modal>
      <Modal title="Edit Template Message" width={"60%"} open={isEditTemplateMSGModalOpen} okText={"Save"} onOk={handleEditTemplateMSGOk} onCancel={handleEditTemplateMSGCancel}>
        <Row>
          <Col span={14}  >
            <Col span={22}>
              Template Name
              <Input showCount maxLength={50} value={templateMsgTitleState} onChange={(e) => setTemplateMsgTitle(e.target.value)} placeholder="Template message title" />
              <br />
              <br />
              Template message
              <TextArea value={templateMsgTextAreaState} placeholder="Template message content" maxLength={1000}
                autoSize={{
                  minRows: 3,
                }}
                onChange={(e) => onChangeTemplateMsgTextArea(e)}
              />
            </Col>
          </Col>
          <Col span={10} >
            <Row style={{ padding: 5 }}>
              Insert Tags
            </Row>
            <Row style={{ padding: 5 }}>
              <Dropdown menu={{ items: tagOptions, onClick: handleTemplateMsgTagMenuClick }}>
                <Button>
                  <Space>
                    Select Tag
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Col>

  );
}

export default Main;
