import React, { useState } from "react";

import Autocomplete from "react-google-autocomplete";
import { GoogleMapsAPI } from "../config";
import { useNavigate } from "react-router-dom";
function Home() {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <section className="home_banner">
        <div className="container">
          <div className="banner_wrapper">
            <div className="address_input_container">
              <label className="address_label">Enter your home addresss</label>
              <div className="input_cta_wrapper">
                <Autocomplete
                  className="address_input"
                  placeholder="125 W Muriel Dr"
                  apiKey={GoogleMapsAPI}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "us" },
                  }}
                  onPlaceSelected={(place) => {
                    navigate(
                      `location/${place.geometry.location.lat()},${place.geometry.location.lng()}/${
                        place.formatted_address
                      }`
                    );
                    setAddress(
                      `location/${place.geometry.location.lat()},${place.geometry.location.lng()}/${
                        place.formatted_address
                      }`
                    );
                    console.log(place.geometry.location.lat());
                  }}
                />

                <button
                  className="input_search_cta"
                  onClick={() => {
                    address ? navigate(address) : console.log("empty address");
                  }}
                >
                  Get me Offers
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
