//import logo from "./logo.svg";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/reset.css";
import "./App.css";

import Routes from "./routes/routes";
// import Main from "./components/main";
// import Home from "./components/home";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        {/* <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/sms/:location' element={<Main />} />
        </Routes> */}
        <Routes />
      </div>
    </BrowserRouter>
  );
}

export default App;
