import Main from "../pages/main";
import Home from "../pages/home";
import Location from "../pages/location";
const MainRoutes = {
  path: "/",
  // element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },

    {
      path: "sms",
      element: <Main />,
    },
    {
      path: "location/:latlong/:address",
      element: <Location />,
    },
  ],
};

export default MainRoutes;
